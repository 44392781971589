var $Last  = $('.__jsMore').last().data('more'),
    loadFlag,
    $Loader = $('<div>').addClass('article--loader'),
    $loader;

if (typeof($Last) !='undefined' ) {

    function chckFlag(){
        $Last  = $('.__jsMore').last().data('more');
        if($Last) {
            loadFlag = true;
        } else { loadFlag = false;}
    };
    chckFlag();
    
    $(window).scroll(function(){
          if  ($(window).scrollTop() >= $(document).height() - $(window).height()){  
              console.log(loadFlag);
              if (loadFlag) {
                  AddMoreContent();
              };
          }
    });
    
    function AddMoreContent(){

          $.ajax({
                url: $Last,
                beforeSend: function () {
                    loadFlag = false;
                    $loader = $Loader.appendTo($('.__jsMore').last());
                    console.log('loader');
                },
                error: function(){
                    chckFlag();
                    
                },
                success: function (html) {
                    
                       $loader.remove();                                        
                       $(html)
                            .insertAfter($('.__jsMore').last())
                            .hide()
                            .fadeIn(150);
                       $Last  = $('.__jsMore').last().data('more');
                       chckFlag();
                    
                }

            });
     };
}