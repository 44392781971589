/**********************************
 * Teasers
 *********************************/
(function() {
    
    if (!document.querySelector('.teasers--more')) {
        return;    
    }
    if (!document.querySelector('.teasers[data-more]')) {
        hideMore();
        return;
    }

    var teaserFlag = false;
    var $loaderLayer = $('<div>').addClass('teaser--loader');
    var $loader;
    var hideMore = function(){        
        document.querySelector('.teasers--more').style.display = 'none';
    };

    $('.teasers--more').click(function(){
        
        var moreUrl = $('.teasers').last().data('more');
        if (teaserFlag || !moreUrl) return;

        $.ajax({
            url: moreUrl,
            beforeSend: function () {
                $loader = $loaderLayer.clone().insertAfter($('.teasers'));
                teaserFlag = true;

            },
            timeout: 10000,
            error: function(){
                $loader.remove();
                teaserFlag = false;
            },
            success: function (html) {
                $loader.remove(); 
                $(html).fadeIn(200).insertBefore('.teasers--more');
                if (html.indexOf('data-more="')==-1) hideMore();
                teaserFlag = false;
                
            }
        });

    });
})();
