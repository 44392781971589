
(function () {
    // wideo na sg
      if ($('video').length) {
        var $thisVideo = $('.teaser').find('video');
        fireVideo = function () {
          var $thisVideo = $('.teaser').find('video');

          $.each($thisVideo, function (x) {
            if ($thisVideo[x].paused) {
              $(this).parent().mouseover(function () {
                $thisVideo[x].play();
                //console.log(1);
              });
              $(this).parent().mouseout(function () {
                $thisVideo[x].pause();
                //console.log(0);
              });
            }
          });
        };

        fireVideo();
      }
})();