//stats articlesasdas
window.cScrollPercent = (function(){
    
    var scale = 100;
    
    var ts = -1,
        T = null,
        checkPosition = function(){
            var hh = document.querySelector('header.header').scrollHeight, //header height
                vh = document.documentElement.clientHeight, //viewport height
                article;
                if (!activeSlug || activeSlug.length <2 ) {
                    article = document.querySelector('.__js_stats_wrapper[data-id]');
                } else {
                    article = document.querySelector('.__js_stats_wrapper[data-id = ' + activeSlug + ']');
                }
            
            if (!article) return;
            
            var tt = parseInt(article.getBoundingClientRect().top),
                bt = parseInt(article.getBoundingClientRect().bottom),
                g = parseInt(vh - tt - hh), //góra artykułu w viewport
                d = parseInt(bt - hh), //dół artykułu w viewport
                r = parseInt((article.scrollHeight + hh) / scale); //ratio
           
                //Jeżeli nie ma tablicy flag dla tego obiektu to utwórz
                if (T == null) {
                    T = {
                            slug: activeSlug, 
                            consumed: {},
                            flags: []
                        };
                    //inicjacja tablic flag dla kolejnych części
                    for (var k=1; k<=scale; k++) {
                        T.flags[k] = {};
                        T.flags[k].begin = 0;
                        T.flags[k].end = 0;
                    }                    
                }    
            
                for (var k=1; k<=scale; k++) {
                    if (T.slug == activeSlug) {
                        if (!T.flags[k].begin && tt < -(k-1) * r + vh + hh && tt > -(k-1) * r ) T.flags[k].begin = 1;
                        if (!T.flags[k].end   && tt < - k * r + vh + hh && tt > -k * r )        T.flags[k].end   = 1;                       
                    }
                }
                
                for (var k=1; k<=scale; k++) {
                    if (!T.consumed[k] && T.flags[k].begin && T.flags[k].end) {
                        // staty dla setnych
                        T.consumed[k] = 1;
                    }
                }
        },
        getValue = function(){
            if (!T) return 0;
            var prc = Object.keys(T.consumed).length;
            T = null; //resetowanie tablicy przy odczycie
            return prc;
        };
    
    window.addEventListener('load', function(){
        ts = setInterval(checkPosition, 250);
    });
    return {
        burp: getValue,
        debug: function(){
            console.log(T);
        }
    };
})();
