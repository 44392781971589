//ver 1.1 GT

'use strict';
function sendStats(category, action, label, value) {
    if(value) {
        gtag('gtag_event', action + '-' + value, {
            'event_category': category,
            'event_label': label,
        });
        try {
            WP.stat.show(wp_sn, {"category": category, "label": label, "value": value, "asproduct": asproduct, "asorderid": asorderid, "asname": asname }, action);
            //console.log('sendStats ' + category + ', ' +action + ", " +label + ", " + value + ", asproduct:" + asproduct + ", asorderid: " + asorderid + ", asname: " + asname);
        }
        catch(e){
            console.log('stat.show ERROR: ' + e);
        }
    }
    else {
        
        gtag('gtag_event', action, {
                'event_category': category,
                'event_label': label
        });
        
        try {
            WP.stat.show(wp_sn, {"category": category, "label": label, "asproduct": asproduct, "asorderid": asorderid, "asname": asname }, action);
            //console.log('sendStats ' + category + ', ' +action + ", " +label + ", asproduct:" + asproduct + ", asorderid: " + asorderid + ", asname: " + asname);
        }
        catch(e){
            console.log('stat.show ERROR: ' + e);
        }
        
    }
}


var custom360Action = function(j) {
    WP.pwp.customAction({"ga": j });
};


var scroll100Indicator = 0;
var unloadCollector = new Array(); // { id0, viewedCount0, itemCount0 }, { id1, viewedCount1, itemCount1 }  tablica do inteaktuwności sliderowych.
var forUnloadCollector = function(id, viewedCount, itemCount) {
        var objectData = new Object();
        objectData.id = id;
        objectData.viewedCount = viewedCount;
        objectData.itemCount = itemCount;
        unloadCollector.push(objectData);
    // unloadCollector push {id, viewedCount, itemCount}

};

// unload
var indT = new Array();

var unloadData = function() {

   var ac = ac || lastReportedSlug;

   console.log('ac: ' + ac);

   if (!ac) return;

   var index = $('#'+ac).index();
   var count = 0;
   for (var i = 0; i < indT.length; ++i) {
       if(indT[i] == (index - 1))
           count++;
   }
   if (count = 4) {
       scroll100Indicator = 1;
   }
   else { scroll100Indicator = 0; };

   var statElementArray = [];

   for (var pos in statElementCollector) {
       statElementArray.push(statElementCollector[pos]);
   };

   var cScrollPercentValue = cScrollPercent.burp() || 0;

   try {
          WP.pwp.closePage({
          "ga": {
            "event": "unload",
            "page": {
                "sliderConsumption": statElementArray,
                "content": {"section": {"scroll100Indicator": scroll100Indicator}},
                "ac": ac,
                "templateType": "AS"
            },
            "content": {"pageViewCount": 1, scrollPercent: "", cScrollPercent: cScrollPercentValue } //pageViewCount ustalone s Salestube na 1 w projektach z doładowywanymi artykułami bądź z wieloma artykułami na jednym widoku, scrollPercent dla danego artukułu, cScrollPercent dla całej strony
          }
        });
    }
    catch(error) {
      console.error('błąd wpjslib WP.pwp: ', error);
    }
};

window.addEventListener("beforeunload", unloadData);

