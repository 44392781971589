
(function () {
    var currScrollTop = 0,
        lastScrollTop = 0,
        wH = window.innerHeight,
        $B = $('body, html'),
        $header = $('header.header div.fixed');
      
      var onScrollChClass = function () {
          
        //jazda headera
        currScrollTop = $(window).scrollTop();

        if (currScrollTop >= wH && $header.not(':animated')) {
          $header.addClass('topbarAnim');
        } else {
          $header.removeClass('topbarAnim');
        }

        
      };
    $(window).on('scroll', onScrollChClass);
   
})();

