//ver 1.3 GT ga360	
//kotwice z nawigacji + pv i vpv
console.log('v1.3.1');

var activeSlug;
var isScrolling = false,// gdy strona się scrolluje to zmienna ma wartość true i nie wysyłamy statów na hover np. akordeon, hotpoint
    lastReportedSlug = null;
var wp_content_selector,
    firstArticleSlug = $('article').eq(0).data('id'),
    firstParagraphSlug = $('article').eq(0).data('id');

(function () {
    
    var initSlug = document.location.pathname.split("/").pop();

    if (!document.getElementsByClassName('__js_stats_wrapper').length ||
        document.location.href.indexOf('.html') > 0) return;

    if (typeof(serviceBaseUrl) == "undefined") { // zabezpieczenie przed brakiem serviceBaseUrl
                                                 // dodawane po stronie backendu w html-u (serwis jest wystawiany z folderem w URLu)
        console.error('ERROR: serviceBaseUrl is no defined!');
        window.serviceBaseUrl = "";
    }
    
    activeSlug = initSlug;
    
    var customOffset = 0,
        animationTime = 1000,
        $sections = $('.__js_stats_wrapper'),

        // Przewijanie do sekcji slug jeżeli w URL jest ustawiony jakiś slug na starcie
        
        scrollOnStart = function () {
            reportSlug();
            sendStats('progress', 'start', initSlug);

        },

        // staty pageviews i scrollboost pwp ----- {
        
        reportSlugTS = -1;
        //alert(firstArticleSlug);
        var reportSlug = function() {
            if (firstArticleSlug !== null) {
                //wyślij statystykę pageview standard
                
                wp_dot_addparams.ctags = statData[firstArticleSlug].tags;
                try {
                    WP.pwp.viewPage({
                        "ga": { "event": "pageview", "page": {"pageviewType": "standard", "error404": basicStatData.ga.page.error404, "templateType": "AS"},
                        "site": {
                            "name": basicStatData.ga.site.name,
                            "version": basicStatData.ga.site.version,
                            "cms": basicStatData.ga.site.cms,
                            "as": {"orderId": asorderid, "productName": asproduct, "name": asname}
                        },
                        "content": {
                            "title": statData[firstArticleSlug].title + ' ' + activeSlug,
                            "category" : {
                                IAB: statData[firstArticleSlug].category.IAB,
                                Gemius: statData[firstArticleSlug].category.Gemius
                            },
                            "tags": statData[firstArticleSlug].tags
                        }
                    }});
                }
                catch(e) { console.log('WP.pwp error: ' + e); }
                
            }
            lastReportedSlug = activeSlug;
            reportSlugTS = -1;
        };
     

    if (initSlug.length > 1 && initSlug!=="undefined") {

        setTimeout(scrollOnStart, 800);
    }

    
})();