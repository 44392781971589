//hotpoints bez autorotacji //elementy hover nia mają mieć satystyk ga360
 
//
//$('.hotpoints--poi').mouseover(function(){
//    
//    var ga360id = $(this).parents('.hotpoints').data("ga360-id"),
//        $gId = $(this).parents('.hotpoints').data("id"),
//        i = $(this).index();
//        
//        
//        $(this).parents('.hotpoints--poi').removeClass('hover');
//        $(this).parents().find('.hotpoints--poi-txt').removeClass('hover');
//        
//        $(this).addClass('hover');
//        $(this).parents().find('.hotpoints--poi-txt').eq(i).addClass('hover');
//    
//        $(this).parents().find('.hotpoints--mobile').text($(this).find('.hotpoints--poi-txt').html()); 
//    
//        if (!isScrolligFlag) {
//            WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName":$gId, "eventAction": deviceTouch?"tap":"hover"}});
//        }
//    } 
//);
//
//
"use strict";

var galleriesHover = $(".hotpoints").each(function(){
    //sprawdzamy, czy jest ustawiony id dla statystyk
    var ga360id = $(this).data('ga360-id');
    var $artID = $(this).parents('.__js_stats_wrapper').data('id');
    if (ga360id == undefined) {
        console.log('%c dodać ga360-id do Galerii hover: ' + $(this).data('id'), 'background: #553311; padding: 3px; color: #ffaaaa');
    } else {
        statElementCollector[ga360id + '-' + $artID] = {
            id: ga360id,
            moduleName: $(this).data('id'),
            viewedCount: 0,
            itemCount:  $(this).find('.hotpoints--poi').length
        };
    };
    $(this).statVievedSlide = [];
    //pozycje

    slidePositionVieved[ga360id + '-' + $artID] = {};  //restet
});

$('.hotpoints').on("mouseenter", ".hotpoints--poi", function() {

    if (isScrolling) return;
    var $artID = $(this).parents('.__js_stats_wrapper').data('id'),
        $galID = $(this).parents('.hotpoints').data('id'),
        active = $(this).index(),
        ga360id = $(this).parents('.hotpoints').data('ga360-id');
        
    if(!ga360id) {console.log('%c dodać ga360-id do patchworka!' + $galID + 'background: #3b3b3b; padding: 3px; color: #beeb2f');}
    var activeItem = $(this).index();
    //console.log('art:' + $artID + 'gal:' + $galID + ' / img: ' + $imgID);
    slidePositionVieved[ ga360id + '-' + $artID][ activeItem ] = 1;
    statElementCollector[ga360id + '-' + $artID]['viewedCount'] = Object.keys(slidePositionVieved[ ga360id + '-' + $artID]).length;

    sendStats('gallery', 'hotpoins', $artID + ' / ' + $galID, active);

    if (!isScrolligFlag) {
        WP.pwp.customAction({"ga": {"event": "content_interaction","eventCategory": ga360id, "moduleName": $galID ,"eventAction": deviceTouch?"tap":"hover"}});
    }

});




$('.hotpoints--mobile-close').on('click', function() {
    
    $(this).parents('.hotpoints--poi').removeClass('hover');
    $(this).parents().find('.hotpoints--poi-txt').removeClass('hover');
    
    } 
);


